// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = '/geos';

export default {
  async getGeo() {
    const response = await requestService.get(`${prefix}`);
    return response?.data?.data;
  },
  async addGeo(payload) {
    const response = await requestService.post(`${prefix}`, payload, {}, false);
    return response?.data?.data;
  },
  async updateGeo(geoId, payload) {
    const response = await requestService.post(`${prefix}/${geoId}`, payload, {}, false);
    return response?.data?.data;
  },
  async deleteGeo(geoId) {
    const response = await requestService.delete(`${prefix}/${geoId}`);
    return response?.data?.data;
  }
};
